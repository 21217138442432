type IFileOrKey = string | { storageKey: string } | { storageKey: string }[];
// type IFileOrKey = string | { storageKey: string };

export function getFileUrl(file: IFileOrKey, last = false) {
  if (Array.isArray(file)) {
    if (file.length) {
      return getFileUrl(file[last ? file.length - 1 : 0]);
    }
    return '';
  }
  if (typeof(file) == 'object' && file && 'storageKey' in file) {
    file = file.storageKey;
  }
  return `${fileUrl}${file}`;
} 
